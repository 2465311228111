header {
    width: 100%;
    position: relative;
    background: @white;
    .greytop {
        background: @grey;
        padding: 5px 0;
        text-align: right;
        color: @white;
        a {
            padding-left: 20px;
        }
    }
    .greytop-wrap {
        width: 100%;
        max-width: 1400px;
        margin: 0 auto;
        padding: 0 25px;
        display: flex;
        justify-content: flex-end;
        .nav__lvl1 {
            :hover {
                text-decoration: none;
                color: white;
            }
        }
    }
    .spacer_top {
        width: 100%;
        @media (max-width: 1400px) {
            width: 100%;
        }
        @media (max-width: 1300px) {
            width: 100%;
        }
        @media (max-width: 1150px) {
            width: 100%;
        }
        @media (max-width: 1050px) {
            width: 100%;
        }
        @media (max-width: 870px) {
            width: 100%;
        }
    }
    
    .imp_data {
        list-style: none;
        display: flex;
        margin: 0;
        padding-left: 0px;
        li {
            padding-right: 10px;
        }
        @media (max-width: 800px) {
            display: none;
        }
    }
    .header_URL {
        padding-left: 49px;
        white-space: nowrap;
        :hover {
            color: white;
            text-decoration: none;
        }
        @media screen and (max-width: 525px){
            display: none;
        }
    }
    .header_contact-link {
        white-space: nowrap;
        :hover {
            color: white;
            text-decoration: none;
        }
        a {
            padding-left: 0px;
        }
    }
    .header-content {
        display: flex;
        padding: 25px 0px;
        align-items: flex-end;
        flex-wrap: wrap;
        @media screen and (max-width: 800px){
            flex-wrap: nowrap;
        }
    }
    .header__logolink {
        display: block;
    }
    .logo-wrap {
        max-width: 300px;
        width: 100%;
        height: auto;
    }
    .headerspacer {
        flex: 0 1 50px;
        @media (max-width: 1180px) {
            flex: 1 1 100%;
        }
        @media (max-width: 800px) {
            flex: 1 1 0px;
        }
    }
    .langmenu {
        padding-left: 50px;
        list-style: none;
        margin: 0;
        padding-right: 5px;
        .current {
            display: none;
        }
        a {
            padding: 0;
            height: 20px;
            margin-top: -2px;
            display: block;
        }
        img {
            width: auto;
            height: 22px;
        }
    }
    .nav__top {
        list-style: none;
        margin: 0;
        text-align: right;
        display: flex;
        justify-content: flex-end;
        white-space: nowrap;
        :hover {
            color: white;
            text-decoration: none;
        }
        @media screen and (max-width: 800px) {
            display: none;
        }
        :nth-child(2) {
            padding-left: 3px;
        }
        
    }

}
