.col {
    @media @992 {
        display: grid;
        grid-column-gap: @spacing;
        grid-template-columns: 6fr 6fr;
        grid-template-areas:
            "title title"
            "subtitle subtitle"
            "left right";
    }
}
.col__title {
    grid-area: title;
}
.col__subtitle {
    grid-area: subtitle;
}
.col__left {
    grid-area: left;
}
.col__right {
    grid-area: right;
}