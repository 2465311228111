@import (inline) '../css/normalize-7.0.0.css';
@import (inline) '../css/fontawesome.css';
@import (inline) '../css/lightbox.css';
@import (inline) '../css/slick.css';
@import 'modules/_slick';
@import 'modules/_base';
@import 'modules/_fonts';
@import 'modules/_columns';
@import 'modules/_footer';
@import 'modules/_gallery';
@import 'modules/_header';
@import 'modules/_mediaQuerys';
@import 'modules/_navigation';
@import 'modules/_reference';
@import 'modules/_slider';

* {
    .box-sizing(border-box);
} 

html {
    width: 100%;
    height: 100%;
}
body {
    .robotoCondensed();
    color: @grey;
    min-height: 100%;
    // display: grid;
    // grid-template-rows: auto 1fr auto;
    background: @grey;
    display: block;
    position: relative;
    width: 100%;
}
main {
    background: @white;
    display: block;
    position: relative;
    width: 100%;
}
.figcaption h1 {
    height: 50px;
    @media (max-width: 550px) {
        
    }
}
h1, .h1 {
    font-size: 2.2em;
    line-height: 1.2em;
    font-weight: 700;
    margin: @spacing/2 0 @spacing 0;
}
h2, .h2 {
    font-size: 2.2em;
    line-height: 1.2em;
    font-weight: 400;
    margin: @spacing/2 0 @spacing 0;
}
h3, .h3 {
    font-size: 1.5em;
    line-height: 1.2em;
    font-weight: 400;
    margin: @spacing/2 0 @spacing 0;
}
a {
    display: inline-block;
    color: @lightGrey;
    text-decoration: none;
    &:hover {
        text-decoration: underline;
    }
}
.text__element, .textcolumn__0, .hyphen {
    padding-bottom: 45px;
}

img {
    display: block;
    width: 100%;
    height: auto;
    border-style: none;
}

.content-wrap {
    width: 100%;
    max-width: @maxWidth;
    margin: 0 auto;
    padding: 0 @spacing;
    p {
        margin: 0;
    }
    h2 {
        margin: 32px 0;
    }
    td {
        padding-right: 20px;
    }
}
.slider__slides {
    margin: 0;
}

@media @395 {
    h1, .h1,
    h2, .h2 {
        font-size: 2em;
    }
    h3, .h3 {
        font-size: 1.8em;
    }
}

@media @570 {
    h1, .h1,
    h2, .h2 {
        font-size: 2em;
    }
    h3, .h3 {
        font-size: 1.8em;
    }
}

@media @670 {
    h1, .h1,
    h2, .h2 {
        font-size: 2.8em;
    }
    h3, .h3 {
        font-size: 1.8em;
    }
}

@media @845 {
    h1, .h1,
    h2, .h2 {
        font-size: 2.8em;
    }
    h3, .h3 {
        font-size: 1.8em;
    }
}

@media @992 {
    h1, .h1,
    h2, .h2 {
        font-size: 3.35em; //40pt
    }
    h3, .h3 {
        font-size: 2em; //24pt
    }
}
.contenttable {
    td {
        vertical-align: top;
    }
}


