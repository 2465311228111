
.slider_arrow {
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    display: block;
    position: absolute;
    bottom: 20px;
    z-index: 5;
    cursor: pointer;
    &.slick-prev {
        border-right: 10px solid @white;
        left: 20px;
    }
    &.slick-next {
        right: 20px;
        border-left: 10px solid @white;
    }
}


.slider {
    border-bottom: solid 5px @yellow;
    // max-height: 600px;
    width: 100%;
    display: block;
    position: relative;
    height: auto;
    // overflow: hidden;
}
.slider__slides {
    padding: 0;
    width: 100%;
    position: relative;
    display: block;
    height: auto;
}
.slider__slide {
    display: block !important;
    list-style: none;
}

.figure {
    position: relative;
    margin: 0;
    // max-height: 1px;
    height: auto;
}
.figcaption {
    position: absolute;
    top: 0;
    width: 100%;
    max-width: 1400px;
    left: 50%;
    .transform(translateX(-50%));
    color: white;
    padding: 0 @spacing;
}