// .nav {
    
// }
.nav__lvl1 {
    list-style: none;
    display: flex;
    margin: 0;
    margin-bottom: -3px;
    flex-wrap: wrap;
    padding: 0px;
    @media screen and (max-width: 800px){
        display: block;
        position: absolute;
        top: 30px;
        background: @white;
        right: 0px;
        margin-top: 50px;
        display: none;
        
    }
}

.nav__item {
    white-space: nowrap;
    :hover {
        border-bottom: 3px solid #268338;
        text-decoration: none;
    }
    a {
        padding: 10px calc(@spacing/2);
        color: black;
        padding-bottom: 0px;
        @media screen and (max-width: 800px){
            padding-bottom: 3px;
        }
    }
}

.nav__button {
    display: block;
    position: relative;
    z-index: 1;
    -webkit-user-select: none;
    user-select: none;
    label {
        padding-right: 5px;
    }
}
.nav__button-burger {
    display: none;
    @media screen and (max-width: 800px){
        display: block;
    }
    width: 33px;
    height: 4px;
    margin-bottom: 5px;
    background: @grey;
    border-radius: 3px;
    transform-origin: 4px 0px;
    transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                opacity 0.55s ease;
    &:first-child {
        transform-origin: 0% 0%;
    }
    &:nth-child(2) {
        right: 0;
        top: 0;
    }
    &:nth-child(3) {
        right: 0;
        top: 9px;
    }
    &:nth-last-child(2) {
        right: 0;
        top: 18px;
        transform-origin: 0% 100%;
    }
}
.nav__button label{
    cursor: pointer;
    position: relative;
    display: block;
    z-index: 1;
    padding-left: 50px;
}
.nav__button-check {
    display: block;
    width: 40px;
    height: 32px;
    top: -7px;
    right: -4px;
    cursor: pointer;
    opacity: 0;
    visibility: hidden;
    z-index: 2;
    -webkit-touch-callout: none;
    &:checked ~ label .nav__button-burger {
        opacity: 1;
        transform: rotate(45deg) translate(-4px, -18px);
        background: @lightGrey;
    }
    &:checked ~ label .nav__button-burger:nth-last-child(3) {
       opacity: 0;
        transform: rotate(0deg) scale(0.2, 0.2);
    }
    &:checked ~ label .nav__button-burger:nth-last-child(2) {
        transform: rotate(-45deg) translate(0, 17px);
    }
    &:checked ~ .nav__lvl1 {
        display: block;
    }
}
.nav__link {
    border-bottom: 3px solid transparent;
}
.nav__link--current {
    // background: #268338a8;
    border-bottom: 3px solid #268338;
}
.nav__show {
    display: none;
    @media screen and (max-width: 800px) {
        display: block;
    }
}


