.gallery {
    display: flex;
    flex-wrap: wrap;
    margin: -10px;
    margin-bottom: 0;
}
.gallery__item {
    width: calc(100% - 20px);
    margin: 10px;
    @media @610 {
        width: calc(50% - 20px);
    }
    @media @845 {
        width: calc(33.3% - 20px);
    }
}
.gallery__link {
    display: block;
}
.gallery__image-wrap {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 67%;
    overflow: hidden;
}
.gallery__image {
    position: absolute;
    width: 100%;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
}