/* roboto-condensed-regular - latin_cyrillic-ext_cyrillic */
@font-face {
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 400;
    src: url('../Fonts/roboto-condensed/roboto-condensed-v25-latin_cyrillic-ext_cyrillic-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../Fonts/roboto-condensed/roboto-condensed-v25-latin_cyrillic-ext_cyrillic-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../Fonts/roboto-condensed/roboto-condensed-v25-latin_cyrillic-ext_cyrillic-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('../Fonts/roboto-condensed/roboto-condensed-v25-latin_cyrillic-ext_cyrillic-regular.woff') format('woff'), /* Modern Browsers */
         url('../Fonts/roboto-condensed/roboto-condensed-v25-latin_cyrillic-ext_cyrillic-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../Fonts/roboto-condensed/roboto-condensed-v25-latin_cyrillic-ext_cyrillic-regular.svg#RobotoCondensed') format('svg'); /* Legacy iOS */
  }
  /* roboto-condensed-700 - latin_cyrillic-ext_cyrillic */
  @font-face {
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 700;
    src: url('../Fonts/roboto-condensed/roboto-condensed-v25-latin_cyrillic-ext_cyrillic-700.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../Fonts/roboto-condensed/roboto-condensed-v25-latin_cyrillic-ext_cyrillic-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../Fonts/roboto-condensed/roboto-condensed-v25-latin_cyrillic-ext_cyrillic-700.woff2') format('woff2'), /* Super Modern Browsers */
         url('../Fonts/roboto-condensed/roboto-condensed-v25-latin_cyrillic-ext_cyrillic-700.woff') format('woff'), /* Modern Browsers */
         url('../Fonts/roboto-condensed/roboto-condensed-v25-latin_cyrillic-ext_cyrillic-700.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../Fonts/roboto-condensed/roboto-condensed-v25-latin_cyrillic-ext_cyrillic-700.svg#RobotoCondensed') format('svg'); /* Legacy iOS */
  }

.robotoCondensed() {
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 16px;
    line-height: 1.2em;
    font-weight: 400;
}