.footer {
    background: #44494f;
    margin-top: 25px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin-left: 9em;
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
    padding: 0 25px;
    align-items: flex-end;
    padding-bottom: 20px;
    @media (max-width: 898px) {
        padding-top: 20px;
    }
}
.footer_logo,  .footer_com {
    color: #9da4ad;
    height: auto;
    padding-right: 5em;
    margin: 0px;
    margin-top: 20px;
}

.footer_adress {
    color: #9da4ad;
    display: flex;
    margin: 0px;
    margin-bottom: -3px;
    a:hover {
        text-decoration: none;
        color: white;
    }
    @media (max-width: 898px) {
        width: 50%;
    }
    @media (max-width: 595px) {
        width: 100%;
        padding-bottom: 15px;
    }
    p {
        padding-right: 25px;
        margin-bottom: 0px;
        align-self: flex-end;
    }
}
.fa-map-marker {
    margin: 20px 0px;
    padding-right: 10px;
    color: white;
    margin-bottom: 5px;
    align-self: flex-end;
    display: flex;
}
.footer_com {
    display: flex;
    margin: 0px;
    margin-bottom: -3px;
    a:hover {
        text-decoration: none;
        color: white;
    }
    @media (max-width: 898px) {
        width: 50%;
        white-space: nowrap;
    }
    @media (max-width: 595px) {
        width: 100%;
    }
    p {
        margin: 0px;
    }
}
.fa-phone {
    padding-right: 10px;
    color: white;
}
.footer_logo {
    
    @media (max-width: 898px) {
        width: 100%;
    }
    img {
        width: 175px;
    }
}
.footer_spacer {
    flex: 1 0 0;
    @media (max-width: 440px) {
        display: none;
    }
}

.medienmonster {
    margin: 0;
    width: 250px;
    margin: 0px;
    padding-bottom: 3px;
    margin-bottom: -3px;
    @media (max-width: 1148px) {
        padding: 10px 0px;
    }
    @media (max-width: 595px) {
        padding-top: 15px;
    }
    :hover {
        color: white;
        text-decoration: none;
    }
}
.mm_text {
    margin: 0;
    padding-bottom: 3px;
}
.mm_footer-img {
    display: block;
    height: auto;
    border-style: none;
    width: 70%;
}